import { IGradeSystem } from "../../../configurations/clientsConfigurations"
import { getColorToGradeSystem } from "../../../tools";
import { IFact } from "./../../../services/api.d"
import { restoreShortTitles, shortenTitles } from "./ChartTools";

const bigChartBarsConfig = (data: Array<IFact>, gradeSystem: IGradeSystem) => {
    const labels = data.map(e => e.title);
    const labelsToShorted = shortenTitles(labels, 25);
    const scores = data.map(e => gradeSystem.convertScore(e.score));
    const colors = scores.map(e => getColorToGradeSystem(gradeSystem, e).color)

    return {
        data: {
            labels: labelsToShorted,
            datasets: [
                {
                    type: 'bar',
                    label: "Activity Scores",
                    data: scores,
                    backgroundColor: colors
                }
            ]
        },
        options: {
            scale: {
                y: {
                    max: gradeSystem.max,
                    min: gradeSystem.min,
                    ticks: {
                        stepSize: gradeSystem.steps
                    },
                    beforeFit: (scale: any) => {

                        if (gradeSystem.labels !== null) {
                            for (let i = 0; i < scale.ticks.length; i++) {
                                const label = gradeSystem.labels.find(e => e.value === scale.ticks[i].value)?.label
                                scale.ticks[i].label = label ? label : "";
                            }
                        }
                    }

                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: restoreShortTitles(labels)
                    }
                },
            }

        },
        plugins: []
    }
}

export default bigChartBarsConfig