import './App.css';
import { createTheme } from '@mui/material';
import { XThemeProvider } from '@ximdex/xui-react/providers';
import Routes from './routes/Routes';


// const theme = createTheme({
//   palette: {
//     mode: 'light',
//     primary: {
//       main: '#43a1a2',
//       contrastText: '#ffffff',
//     },
//     secondary: {
//       main: '#214F61',
//     },
//     background: {
//       default: "#eee",
//       paper: "white"
//     }

//   },
//   properties: {
//     padding: {
//       sm: '1rem'
//     }
//   }
// })

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0A223D',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#0A223D',
    },
    background: {
      default: "#eee",
      paper: "white"
    }

  },
  properties: {
    padding: {
      sm: '1rem'
    }
  }
});



function App() {
  window.document.body.style.backgroundColor = theme.palette.background.default

  window.document.title = 'Ximdex Dashboard'

  return (
    <div className="App">
      <XThemeProvider lightTheme={theme}>
        <Routes />
      </XThemeProvider>
    </div>
  );
}

export default App;
