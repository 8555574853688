import { IGradeSystem } from "../../../configurations/clientsConfigurations"
import { getColorToGradeSystem } from "../../../tools";
import { IFact } from "./../../../services/api.d"
import { restoreShortTitles, shortenTitles } from "./ChartTools";

const bigChartRadarConfig = (data: Array<IFact>, gradeSystem: IGradeSystem) => {
    const labels = data.map(e => e.title);
    const labelsToShorted = shortenTitles(labels, 25);
    const scores = data.map(e => gradeSystem.convertScore(e.score));
    const colors = scores.map(e => getColorToGradeSystem(gradeSystem, e).color)

    return {
        type: 'radar',
        data: {
            labels: labelsToShorted,
            datasets: [
                {
                    label: "Activity Scores",
                    data: scores,
                    fill: false,
                    borderColor: colors,
                    pointHoverBackgroundColor: colors,
                    pointBackgroundColor: colors,
                    backgroundColor: colors.map(color => color + "66")
                }
            ]
        },
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: restoreShortTitles(labels)
                    }
                },
            },
            scales: {
                r: {
                    pointLabels: {
                        font: {
                            size: 15
                        }
                    }
                }
            },
            scale: {
                max: gradeSystem.max,
                min: gradeSystem.min - 1,
                stepSize: gradeSystem.steps
            },
        },
        plugins: []
    }
}

export default bigChartRadarConfig