import * as React from 'react';
import './Circle.scss'
const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) => Math.round((100 - Math.min(val, 100)) / 100 * diameter);



export default class Circle extends React.Component {
  static defaultProps = {
    progress: 0,
    animate: true,
    animationDuration: '1s',
    showNumber: true,
    numberSymbol: "%",
    progressColor: '#43A1A2',
    bgColor: '#DFDFDF',
    textColor: 'black',
    title: "Average grade",
    size: '100',
    lineWidth: '25',
    percentSpacing: 10,
    label: true,
    textStyle: { font: '8rem Helvetica, Arial, sans-serif' },
    onPaintText: (text) => { return text },
    onPaintProgressColor: (color, progress) => { return color }
  }

  get text() {
    const { progress, showNumber, textColor, textStyle, percentSpacing, numberSymbol, onPaintText } = this.props;
    if (!showNumber) return null;

    return (
      <text style={textStyle} fill={textColor} x={radius} y={radius} textAnchor="middle" dominantBaseline="central">
        {onPaintText ? onPaintText(progress) : progress}{numberSymbol !== "" ? <tspan dx={percentSpacing}>{numberSymbol}</tspan> : null}
      </text>
    );
  }

  render() {
    const { text } = this;
    const { label, progress, size, bgColor, progressColor, lineWidth, animate, animationDuration, roundedStroke, title, responsive, onAnimationEnd, onPaintProgressColor } = this.props;
    const strokeDashoffset = getOffset(progress);
    const transition = animate ? `stroke-dashoffset ${animationDuration} ease-out` : undefined;
    const strokeLinecap = roundedStroke ? 'round' : 'butt';

    const svgSize = responsive ? '100%' : parseInt(size) + 30;

    let strokeColor = (onPaintProgressColor && progress !== null) ? onPaintProgressColor(progressColor, progress) : progressColor;

    return (
      <div className="position-relative d-flex justify-content-center mb-1">
        <svg width={svgSize} height={svgSize} viewBox="-50 -50 450 450" className={this.props.className + ' mx-auto circle'}>
          <circle stroke={bgColor} cx="175" cy="175" r="175" strokeWidth={lineWidth} fill="none" />
          <circle stroke={strokeColor} transform="rotate(-90 175 175)" cx="175" cy="175" r="175" strokeDasharray="1100" strokeWidth={lineWidth} strokeDashoffset="1100" strokeLinecap={strokeLinecap} fill="none" style={{ strokeDashoffset, transition }} onTransitionEnd={onAnimationEnd} />
          {text}

        </svg>
        {label &&
          <p className="note-mark">{title}</p>
        }

      </div>
    );
  }
}
