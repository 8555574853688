export const restoreShortTitles = (labels: Array<string>) => {
    return function (tooltipItem: any, data: any) {
        let sizeLine = 35;
        let title = '';
        let stringTitle = labels[tooltipItem[0].dataIndex];
        for (let i = 0; i < stringTitle.length; i++) {
            if (!(i % sizeLine === 1 && stringTitle[i] === " ") || i === 0) {
                title += stringTitle[i];
            }

            if (i % sizeLine === 0 && i !== 0) title += "\n\r";
        }
        return title;
    }
}

export const shortenTitles = (labels: Array<string>, limit: number): Array<string> => {
    labels = Object.assign([], labels)
    for (let i = 0; i < labels.length; i++) {
        if (labels[i]) {
            if (labels[i].length > limit) {
                labels[i] = labels[i].substring(0, limit - 3) + "...";
            }
        }
    }
    return labels;
}

