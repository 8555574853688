import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListPage from '../../pages/ListPage/ListPage';
import User from '../../hooks/User';

import { useTranslation } from 'react-i18next';

import './LoginRoutes.scss';

import config from '../../config';
import { PoweredBy } from '../../components/PoweredBy/PoweredBy';


const logoLink = {
    logoImgSrc: '/static/images/mhe-logo.png',
    href: '/',
    target: '_self'
}

const LoginRoutes = () => {
    const [searchParams] = useSearchParams();
    const [_t] = useTranslation("global");

    let navLink = config.CLIENT_CONFIG.NAV_CONFIG?.map(eList => {
        return eList.map(e => {
            return {
                ...e,
                text: _t(e.text)
            };
        })
    })

    
    let userId = '';


    let tmpUserId = searchParams.get('user_id') ?? 'unknown';
    let localStorageUserId = localStorage.getItem('user_id');
    if (tmpUserId === 'unknown' && localStorageUserId !== null) {
        tmpUserId = localStorageUserId;
    } else if (tmpUserId === 'unknown' && localStorageUserId === null) {
        //toast.error(_t("errors.user-needed"));
    } else {
        localStorage.setItem('user_id', tmpUserId);
    }
    userId = tmpUserId;

    return (
        <>
            <nav className="navbar">
                <div className="navbar-image-container">
                    <img src={logoLink.logoImgSrc}></img>
                </div>
                <div className="navbar-user-info">
                    <div className="navbar-user-icon">
                        <p>{userId[0].toUpperCase()}</p>
                    </div>
                    {userId}
                </div>
            </nav>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Routes>
                <Route path='/' />
                {config.CLIENT_CONFIG.ROUTES_CONFIG.map((e, index) =>
                    <Route key={index} path={e.route} element={<ListPage
                        user_id={userId}
                        title={e.title}
                        resourceType={e.resource}
                        configurationRoute={e}
                    />
                    }
                    />)
                }
            </Routes>
            <div style={{ width: '100%', height: '100px' }}>
                <PoweredBy />
            </div>
            <div style={{ width: '100%', height: '100px', position: 'absolute', bottom: '0px' }}>
                <PoweredBy />
            </div>

        </>
    )
}

export default LoginRoutes