import React from 'react'
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { getConfigMiniGraph } from './configurations/miniChartConfig';
import { IMiniChartProps } from './miniChart.d';


const MiniChart = ({ style, data, gradingSystem, className = "" }: IMiniChartProps) => {


    const configChart = getConfigMiniGraph(data, gradingSystem);
    return <Bar
        {...configChart}
        style={style}
    // className={props.className}
    // id={data}
    />
}

export default MiniChart