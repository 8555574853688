import React from 'react'
import { IListProps } from './list.d'
import RowList from './RowList'

const List = ({ dataList, gradingSystem, params, user_id, configurationRoute }: IListProps) => {
    return <>
        {dataList.map(data =>
            <RowList
                configurationRoute={configurationRoute}
                params={params}
                user_id={user_id}
                key={data.id}
                data={data}
                gradingSystem={gradingSystem}
            />
        )}
    </>
}
export default List