
import { IFact } from "./../../../services/api.d"
import { restoreShortTitles, shortenTitles } from "./ChartTools";


const bigChartTimesConfig = (data: Array<IFact>) => {
    const labels = data.map(e => e.title);
    const labelsToShorted = shortenTitles(labels, 25);
    const times = data.map(e => e.time);
    return {
        data: {
            labels: labelsToShorted,
            datasets: [
                {
                    type: 'bar',
                    label: "Duration of activities (mins)",
                    data: times,
                    backgroundColor: "#f36a6a"
                }
            ]
        },
        options: {
            scale: {
                y: {
                    min: 0,
                    beforeFit: (scale: any) => {
                        scale.ticks = scale.ticks.map(e => {
                            e.label = e.value + " mins";
                            return e
                        })
                    }
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: restoreShortTitles(labels)
                    }
                },
            }
        },
        plugins: []
    }
}

export default bigChartTimesConfig