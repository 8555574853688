import React, { useEffect, useState } from 'react'
import { XButton } from '@ximdex/xui-react/material'

import { ListRowDetailsStyles, ListRowStyles, StyledUri } from './ListStyles'

import { IFact } from "../../services/api.d";
import Circle from '../Circle/Circle';
import { firstLetterToUpperCase, getColorToGradeSystem, getLabelToGradeSystem, insertParans } from '../../tools';
import { IGradeSystem } from '../../configurations/clientsConfigurations';
import { getSublist } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IConfigurationRoute } from '../../routes/LoginRoutes/loginRoutes.d';
import { IParams } from '../../types';
import MiniChart from '../MiniChart/MiniChart';
import { Skeleton } from '@mui/material';
import { toast } from 'react-toastify';

export interface IRowListProps {
    data: IFact
    gradingSystem: IGradeSystem
    params: IParams
    user_id: string
    configurationRoute: IConfigurationRoute
}

const pluralTypes = {
    'unit': 'units',
    'activity': 'activities',
    "assessment": "assessments"
}

const RowList = ({ data, gradingSystem, params, user_id, configurationRoute }: IRowListProps) => {
    const navigate = useNavigate()

    const [_Lt] = useTranslation("listPage")
    const [_Gt] = useTranslation("global")

    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
    const [sublist, setSublist] = useState<Array<IFact> | undefined>(undefined)

    useEffect(() => {
        const init = async () => {
            const newSublist = await getSublist(data.sublistResource, user_id)
            if (newSublist === null) {
                //toast.error(_Gt("errors.user-not-found"));
                setSublist([])
            } else {
                setSublist(newSublist?.sublist)
            }
        }
        init()
    }, [data])

    const onClickLink = () => {
        console.log(configurationRoute)
        navigate(insertParans(configurationRoute.list.listHref, {
            ...params,
            id: data.id + ""
        }))
    }

    return (
        <>
            <ListRowStyles>
                <div>
                    <Circle
                        responsive={false}
                        size="50"
                        label={false}
                        lineWidth="90"
                        progress={Math.round(data.score)}
                        numberSymbol=""
                        animate={false}
                        onPaintProgressColor={() => {
                            return getColorToGradeSystem(gradingSystem, gradingSystem.convertScore(data.score)).color
                        }}
                        onPaintText={
                            (score) => getLabelToGradeSystem(gradingSystem, gradingSystem.convertScore(data.score))?.label ?? gradingSystem.convertScore(score)
                        }
                    />
                </div>
                <StyledUri onClick={onClickLink} style={{ textAlign: "left", cursor: 'pointer' }}>
                    {
                        data.type === "assessment" 
                            ? <>
                                {firstLetterToUpperCase(_Gt(`type-data.${data.type}`))}: {data.title}
                            </>
                            : <>
                                {firstLetterToUpperCase(_Gt(`type-data.${data.type}`))} {data.id}: {data.title}
                            </>
                    }
                    
                </StyledUri>
                <XButton
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    color="secondary"
                    height="49px"
                    style={{ marginRight: "1rem" }}
                    loading
                    activeLoading={sublist === undefined}
                >

                    {isCollapsed
                        ? _Lt("lists.buttons.used").replace("{number}", sublist ? sublist.length + "" : "0").replace("{type}", _Gt(`type-data.${pluralTypes[data.typeSublist]}`))
                        : _Lt("lists.buttons.hide").replace("{number}", sublist ? sublist.length + "" : "0").replace("{type}", _Gt(`type-data.${pluralTypes[data.typeSublist]}`))
                    }
                </XButton>
                <div >
                    {sublist ?
                        <MiniChart
                            style={{
                                width: "90%",
                                maxHeight: "60px"
                            }}
                            data={sublist}
                            gradingSystem={gradingSystem}
                        />
                        : <Skeleton variant="rectangular" width={"90%"} height={60} />
                    }

                </div>
            </ListRowStyles>
            {!isCollapsed && sublist && sublist.length > 0 && sublist.map((item, index) => (
                <ListRowDetailsStyles key={index}>
                    <div style={{ maxWidth: 60, marginLeft: "1rem" }}>
                        <Circle
                            responsive={false}
                            size="30"
                            label={false}
                            lineWidth="90"
                            progress={Math.round(item.score)}
                            numberSymbol=""
                            animate={false}
                            onPaintProgressColor={() => {
                                return getColorToGradeSystem(gradingSystem, gradingSystem.convertScore(item.score)).color
                            }}
                            onPaintText={
                                (score) => getLabelToGradeSystem(gradingSystem, gradingSystem.convertScore(item.score))?.label ?? gradingSystem.convertScore(score)
                            }
                        />
                    </div>
                    <span style={{ textAlign: "left", }}>
                        {firstLetterToUpperCase(_Gt(`type-data.${item.type}`))} {item.id}: - {item.title}
                    </span>
                </ListRowDetailsStyles>
            ))}
        </>
    )
}

export default RowList