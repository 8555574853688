import { IGradeSystem } from "../../../configurations/clientsConfigurations"
import { getColorToGradeSystem } from "../../../tools";
import { IFact } from "./../../../services/api.d"
import { restoreShortTitles, shortenTitles } from "./ChartTools";



const bigChartBarsAndTimesConfig = (data: Array<IFact>, gradeSystem: IGradeSystem, titles: { titleScores: string, titleTimes: string }) => {
    const labels = data.map(e => e.title);
    const labelsToShorted = shortenTitles(labels, 18);
    const scores = data.map(e => gradeSystem.convertScore(e.score));
    const times = data.map(e => e.time);
    const colors = scores.map(e => getColorToGradeSystem(gradeSystem, e).color)

    return {
        data: {
            labels: labelsToShorted,
            datasets: [
                {
                    type: 'bar',
                    label: titles.titleScores,
                    data: scores,
                    backgroundColor: colors,
                    yAxisID: 'scores',
                    order: 2
                },
                {
                    type: 'line',
                    label: titles.titleTimes,
                    data: times,
                    backgroundColor: "#f36a6a",
                    borderColor: "#00000019",
                    yAxisID: 'times',
                    order: 1
                }
            ]
        },
        options: {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 15
                        }
                    }
                },
                scores: {
                    max: gradeSystem.max,
                    min: gradeSystem.min,
                    ticks: {
                        stepSize: gradeSystem.steps,
                        font: {
                            size: 15
                        }

                    },
                    beforeFit: (scale: any) => {
                        if (gradeSystem.labels !== null) {
                            for (let i = 0; i < scale.ticks.length; i++) {
                                const label = gradeSystem.labels.find(e => e.value === scale.ticks[i].value)?.label
                                scale.ticks[i].label = label ? label : "";
                            }
                        }

                    },
                    position: 'left'
                },
                times: {
                    min: gradeSystem.min,
                    position: 'right',
                    beforeFit: (scale: any) => {
                        for (let i = 0; i < scale.ticks.length; i++) {
                            scale.ticks[i].label = scale.ticks[i].label + " mins";
                        }
                    },

                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            size: 15
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    labels: {
                        // This more specific font property overrides the global property
                        font: {
                            size: 15
                        }
                    }
                },
                title: {
                    font: {
                        weight: 'bold',
                        size: '30',
                    },
                },
                tooltip: {
                    callbacks: {
                        title: restoreShortTitles(labels)
                    }
                },
            }
        },
        plugins: []
    }
}

export default bigChartBarsAndTimesConfig