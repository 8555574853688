import React from 'react'
import { Bar, Radar, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { IBigChartProps } from "./bigChart.d"
import bigChartBarsAndTimesConfig from './configurations/bigChartBarsAndTimesConfig';
import bigChartBarsConfig from './configurations/bigChartBarsConfig';
import bigChartRadarConfig from './configurations/bigChartRadarConfig';
import bigChartTimesConfig from './configurations/bigChartTimesConfig';

const BigChart = ({ type, data, gradingSystem, className = "" }: IBigChartProps) => {
    const [_t] = useTranslation('listPage')

    if (type === 'bars') {
        const config = bigChartBarsConfig(data, gradingSystem)
        return <Bar {...config} className={className} />
    } else if (type === 'times') {
        const config = bigChartTimesConfig(data)
        return <Line {...config} className={className} />
    } else if (type === 'radar') {
        const config = bigChartRadarConfig(data, gradingSystem)
        return <Radar {...config} className={className} />
    } else if (type === 'barsAndTimes') {
        const config = bigChartBarsAndTimesConfig(data, gradingSystem, {
            titleScores: _t('chart.mhe.scores'),
            titleTimes: _t('chart.mhe.times'),
        })
        return <Bar {...config} className={className} />
    }

    return <div>Not exist this type</div>
}

export default BigChart