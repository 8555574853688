import React, { useState } from 'react'
import LoginRoutes from './LoginRoutes/LoginRoutes';

const Routes = () => {
    const [loggin] = useState(true)

    if (loggin) {
        return <LoginRoutes />
    } else {
        return null;
    }
}

export default Routes