import { IGradeSystem, INavConfiguration, IRoutesConfiguration } from "./clientsConfigurations";

function roundedToFixed(input: number, digits: number): number {
    var rounded = Math.pow(10, digits);
    return +(Math.round(input * rounded) / rounded).toFixed(digits);
}

export const mheDobleChartConfiguration = false

export const mheGradeSystem: IGradeSystem = {
    max: 10,
    min: 0,
    steps: 1,
    convertScore: (s: number) => roundedToFixed(s / 100 * 10, 1),
    colors: [
        {
            maxValue: 0,
            color: '#DE3C4B'
        },
        {
            maxValue: 2,
            color: '#FCAA67'
        },
        {
            maxValue: 4,
            color: '#FFFC5C'
        },
        {
            maxValue: 6,
            color: '#8efd8c'
        },
        {
            maxValue: 8,
            color: '#1effbc'
        },
        {
            maxValue: 10,
            color: '#3DD6D0'
        }
    ],
    labels: null
}

export const mheNavConfiguration: INavConfiguration = [
    [
        {
            text: 'adrisg',
            icon: <img
                src={"https://avatars.githubusercontent.com/u/25623675?s=40&v=4"}
                style={{ width: '30', borderRadius: "50%" }}
                alt="perfil"
            />,
            url: '#',
            target: '_self'
        }
    ]
]

export const mheRoutesConfiguration: IRoutesConfiguration = [
    {
        route: "/isbn/:isbn",
        title: "title.mhe.isbn",
        resource: {
            uri: '/dashboard/{isbn}',
            hasParams: true
        },
        list: {
            listHref: '/isbn/{isbn}/unit/{id}',
            sublistHref: '/{isbn}/list/{id}'
        }
    },
    {
        route: "/isbn/:isbn/unit/:unit",
        title: "title.mhe.unit",
        resource: {
            uri: '/dashboard/{isbn}/units/{unit}',
            hasParams: true
        },
        list: {
            listHref: '#',
            sublistHref: '#',
        }
    }
]