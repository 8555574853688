import config from "./../config";

const esEs = {
    listPage: require("./esEs/listPage.json"),
    global: require("./esEs/global.json"),
}

const en = {
    listPage: require("./en/listPage.json"),
    global: require("./en/global.json"),
}

const getConfigLanguages = () => {
    return {
        interpolation: { escapeValue: false },
        supportedLngs: ["es", "en"],
        fallbackLng: "es",
        resources: {
            "es": esEs,
            en: en
        },
        detection: {
            lookupQuerystring: "lang"
        }
    }
}

export default getConfigLanguages;