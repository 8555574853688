import { IGradeSystem } from "./configurations/clientsConfigurations";

export const getNearestScore = (possibleNotes: Array<number>, score: number) => {
    possibleNotes = possibleNotes.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0
    })
    score = Math.round(score);
    if (possibleNotes[0] >= score) return possibleNotes[0]
    if (possibleNotes[possibleNotes.length - 1] <= score) return possibleNotes[possibleNotes.length - 1];
    if (possibleNotes.includes(score)) return score;
    let indexPosibleScore = possibleNotes.findIndex(pNote => pNote > score)

    let center = (possibleNotes[indexPosibleScore] - possibleNotes[indexPosibleScore - 1]) / 2

    if (center <= score - possibleNotes[indexPosibleScore - 1]) return possibleNotes[indexPosibleScore]
    if (center > score - possibleNotes[indexPosibleScore - 1]) return possibleNotes[indexPosibleScore - 1]

    return possibleNotes[indexPosibleScore]
}

export const getColorToGradeSystem = (gradeSystem: IGradeSystem, score: number): {
    maxValue: number
    color: string
} => {
    const colorScore = getNearestScore(gradeSystem.colors.map(c => c.maxValue), score)
    const targetColor = gradeSystem.colors.find(c => colorScore === c.maxValue);
    return targetColor ? targetColor : gradeSystem.colors[0]
}

export const getLabelToGradeSystem = (gradeSystem: IGradeSystem, score: number) => {
    if (gradeSystem.labels === null) return null;
    const labelScore = getNearestScore(gradeSystem.labels?.map(l => l.value), score)
    const targetLabel = gradeSystem.labels.find(l => labelScore === l.value);
    return targetLabel ? targetLabel : gradeSystem.labels[0]
}

export const firstLetterToUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}


export const insertParans = (str: string, params: { isbn?: string, unit?: string | undefined, id?: string }) => {
    Object.entries(params).forEach(([key, value]) => {
        if (value) str = str.replace(`{${key}}`, value + "")
    })

    return str
}