import { IGradeSystem } from "../../../configurations/clientsConfigurations";
import { IFact } from "../../../services/api.d";
import { getColorToGradeSystem } from "../../../tools";

export const getConfigMiniGraph = (sublit: Array<IFact>, gradingSystem: IGradeSystem) => {
    const scores = sublit.map(e => gradingSystem.convertScore(e.score));
    const colors = scores.map(e => getColorToGradeSystem(gradingSystem, e).color)

    return {
        data: {
            labels: scores,
            datasets: [
                {
                    type: 'bar',
                    data: scores,
                    backgroundColor: colors
                }
            ]
        },
        options: {
            scale: {
                max: gradingSystem.max,
                min: gradingSystem.min
            },
            responsive: false,
            layout: {
                padding: {
                    left: -25
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    beginAtZero: false,
                    display: false,
                    grid: {
                        display: false
                    }
                }
            },
            animation: {
                duration: 0
            }
        }
    };
}