import config from "../config"
import { toast } from 'react-toastify';

import { IPageListResourceResult } from "./api.d"

export interface IParams {
    isbn?: string
    unit?: string
}

export const getResource = async (uri: string, user_id: string) => {
    // slet uri = resource.uri
    // uri = insertParans(uri, params)

    const response = await fetch(config.XEVAL_URI + uri, {
        headers: new Headers({
            'authorization': user_id
        })
    });

    if (response.status === 404) {
        return null;
    };
    const courses = await response.json()
    return courses as IPageListResourceResult
}

export const getSublist = async (uri: string, user_id: string) => {
    const response = await fetch(config.XEVAL_URI + uri, {
        headers: new Headers({
            'authorization': user_id
        })
    });
    if (response.status === 404) {
        return null;
    };
    const courses = await response.json()
    return courses as IPageListResourceResult
}   