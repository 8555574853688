import { styled } from "@mui/material/styles";

export const ListRowStyles = styled('div')`
    width: 100%;
    display: grid;
    grid-template-columns: 0.25fr 2.25fr 0.75fr 0.75fr;
    grid-column-gap: 1rem;
    padding: 0.5rem;
    align-items: center;
    border: 0.5px solid #BBBBBB;
    background: #FBFBFB;
    min-height: 79px !important;
    margin-top: -1px;
`

export const ListRowDetailsStyles = styled('div')`
    display: grid;
    padding: 0.5rem;
    align-items: center;
    border: 0.25px solid #DDD;
    background: white;
    width: 100%;
    min-height: 50px;
    max-height: calc(60px + 16px);
    grid-template-columns: 0.25fr 2.25fr 0.75fr;
    padding-left: 2rem;
`

export const StyledSublistId = styled('p')`
    margin: 1rem;
`;

export const StyledUri = styled('span')`
    &:hover {
        color: blue;
    }
`;