
import { XSwitch } from '@ximdex/xui-react/material'
import { useSpinner } from '@ximdex/xui-react/hooks'
import { useEffect, useState } from 'react'
import Circle from '../../components/Circle/Circle'
import useLocalStorage from '../../hooks/useLocalStorage'
import BigChart from '../../components/BigChart/BigChart'
import List from './../../components/List/List'
import { toast } from 'react-toastify';

import './ListPage.scss'

import config from '../../config'
import { getColorToGradeSystem, getLabelToGradeSystem, insertParans } from '../../tools'
import { getResource } from '../../services/api'


import { IConfigurationResource, IPageListResourceResult } from '../../services/api.d'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Clients from '../../enums/clients'
import { IConfigurationRoute } from '../../routes/LoginRoutes/loginRoutes.d'

type StateList = null | IPageListResourceResult

interface ListPageProps {
    user_id: string
    title: string
    resourceType: IConfigurationResource
    configurationRoute: IConfigurationRoute
}

const ListPage = ({ user_id, resourceType, title, configurationRoute }: ListPageProps) => {
    const [_t] = useTranslation("listPage");
    const [_Gt] = useTranslation("global");
    const { showSpinner, hideSpinner } = useSpinner()
    const params = useParams()
    const { GRADE_SYSTEM: gradingSystem } = config.CLIENT_CONFIG;
    const [list, setList] = useState<StateList>(null);
    const [loadedContent, setLoadedContent] = useState(false);
    const [radarIsActive, setIsRadarActive] = useLocalStorage<boolean>("@xdata:radar-active", false)

    useEffect(() => {
        const init = async () => {
            setList(null)
            setLoadedContent(false);
            showSpinner()
            let newList: StateList = null;
            if (resourceType.hasParams) {
                newList = await getResource(insertParans(resourceType.uri, params), user_id);
            } else {
                newList = await getResource(resourceType.uri, user_id)
            }

            setList(newList)
            setLoadedContent(true);
            hideSpinner()
        }
        init()
    }, [params])

    if (loadedContent === true && list === null) {
        return (
            <div id="not-found">
                <h2>{_Gt("errors.user-not-found")}</h2>
            </div>
        );
    } else if (loadedContent === true && list !== null && list.sublist.length === 0) {
        return (
            <div id="not-found">
                <h2>{_Gt("errors.data-not-found")}</h2>
            </div>
        )
    } else if (loadedContent === true && list !== null){
        return (
            <div className="container pt-5">
                <div className="row ximdex-card position-relative">
                    <div className="col-10 d-flex align-items-center">
                        <h2 id="title-name">
                            {insertParans(_t(title), params)}
                        </h2>
                    </div>
                    <div className="col-1 col-sm-2 col-xs-3 d-flex justify-content-end">
                        <Circle
                            responsive={false}
                            size="50"
                            lineWidth="90"
                            progress={Math.round(list.score)}
                            numberSymbol=""
                            title={_t("title.circle")}
                            animate={false}
                            onPaintProgressColor={() => {
                                return getColorToGradeSystem(gradingSystem, gradingSystem.convertScore(list.score)).color
                            }}
                            onPaintText={
                                (score) => getLabelToGradeSystem(gradingSystem, gradingSystem.convertScore(list.score))?.label ?? gradingSystem.convertScore(score)
                            }
                        />
                    </div>
                    <div className="container-switch-radar">
                        <label htmlFor='radar'>Radar:</label>
                        <XSwitch color='secondary' value={radarIsActive} onChange={() => setIsRadarActive(!radarIsActive)} id="radar" />
                    </div>
                </div>

                <div className="row ">
                    {config.CLIENT_CONFIG.DOBLE_CHART === false ? <>
                        <div className="col-10 mx-auto mt-5 mb-5">
                            {radarIsActive ?
                                (
                                    <div
                                        style={{
                                            maxWidth: '80%',
                                            margin: 'auto',
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: 'center'
                                        }}
                                    >

                                        <BigChart type="radar" data={list.sublist} gradingSystem={gradingSystem} />
                                    </div>

                                ) : (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                        <BigChart type="barsAndTimes" data={list.sublist} gradingSystem={gradingSystem} />
                                    </div>
                                )
                            }
                        </div>

                    </> : <>
                        <div className="col-6 mt-5 mb-5">
                            {radarIsActive ?
                                (
                                    <div style={{ height: '539.77px', display: "flex", justifyContent: "center", alignItems: 'center' }}>

                                        <BigChart type="radar" data={list.sublist} gradingSystem={gradingSystem} />
                                    </div>

                                ) : (
                                    <div style={{ height: '224.6px', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                        <BigChart type="bars" data={list.sublist} gradingSystem={gradingSystem} />
                                    </div>
                                )
                            }
                        </div>
                        <div className="col-6 mt-5 mb-5" style={{ height: radarIsActive ? '539.77' : '224.6px', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                            <BigChart type="times" data={list.sublist} className="w-60" gradingSystem={gradingSystem} />
                        </div>

                    </>}

                </div>
                <div className="row my-4">
                    <List
                        configurationRoute={configurationRoute}
                        params={params}
                        user_id={user_id}
                        dataList={list.sublist} gradingSystem={gradingSystem}
                    />
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default ListPage