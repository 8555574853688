import styled from "styled-components/macro";

export const StyledPoweredByContainer = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 200px;
    margin: calc(1rem - 8px);
`;

export const StyledPoweredByImg = styled.img`
    display: block; 
    width: 100%; 
    margin: 0 auto; 
    cursor: pointer;
`;

export const StyledVersionText = styled.p`
    margin: -4px 8px 0px;
    font-size: 0.8em;
    text-align: right;
`;