import Clients from "../enums/clients";
import { IConfigurationRoute } from "../routes/LoginRoutes/loginRoutes.d";
import { mheDobleChartConfiguration, mheGradeSystem, mheNavConfiguration, mheRoutesConfiguration } from "./mheConfiguration";

export interface IGradeSystem {
    max: number
    min: number,
    steps: number,
    convertScore: (score: number) => number,
    colors: Array<{
        maxValue: number
        color: string
    }>,
    labels: Array<{
        value: number,
        label: string
    }> | null

}
export const GRADE_SYSTEM_CLIENT: { [label: string]: IGradeSystem } = {
    [Clients.mhe]: mheGradeSystem,
    [Clients.default]: {
        max: 100,
        min: 0,
        steps: 20,
        convertScore: (s: number) => s,
        colors: [
            {
                maxValue: 20,
                color: '#bf0411'
            },
            {
                maxValue: 50,
                color: '#f1ce00'
            },
            {
                maxValue: 100,
                color: '#54963d'
            }
        ],
        labels: null
    }
}

export type INavConfiguration = Array<Array<{
    text: string
    url: string
    icon?: JSX.Element,
    target: '_self' | '_blank'
}>> | null

export const NAV_CONFIGURATION_CLIENT: { [label: string]: INavConfiguration } = {
    [Clients.mhe]: mheNavConfiguration,
    [Clients.default]: null
}


export type IRoutesConfiguration = Array<IConfigurationRoute>;
export const ROUTES_CONFIGURATION_CLIENT: { [label: string]: IRoutesConfiguration } = {
    [Clients.mhe]: mheRoutesConfiguration,
    [Clients.default]: mheRoutesConfiguration
}

export const DOBLE_CHART_CONFIGURATION: { [label: string]: boolean } = {
    [Clients.mhe]: mheDobleChartConfiguration,
    [Clients.default]: false
}