import { GRADE_SYSTEM_CLIENT, NAV_CONFIGURATION_CLIENT, IGradeSystem, INavConfiguration, IRoutesConfiguration, ROUTES_CONFIGURATION_CLIENT, DOBLE_CHART_CONFIGURATION } from "./configurations/clientsConfigurations";
import Clients from "./enums/clients";
import Languages from "./enums/Languages";

let {
    REACT_APP_XEVAL_URI,
    REACT_APP_XEVAL_API_RESOURCE,
    REACT_APP_CLIENT,
    REACT_APP_LANGUAGE
} = process.env;

interface IConfig {
    XEVAL_URI: string
    CLIENT: Clients
    CLIENT_CONFIG: {
        GRADE_SYSTEM: IGradeSystem
        NAV_CONFIG: INavConfiguration
        ROUTES_CONFIG: IRoutesConfiguration
        DOBLE_CHART: boolean
    }
    LANGUAGE: Languages
}

let config: IConfig = {
    XEVAL_URI: "",
    CLIENT: Clients.default,
    CLIENT_CONFIG: {
        GRADE_SYSTEM: GRADE_SYSTEM_CLIENT[Clients.default],
        NAV_CONFIG: NAV_CONFIGURATION_CLIENT[Clients.default],
        ROUTES_CONFIG: ROUTES_CONFIGURATION_CLIENT[Clients.default],
        DOBLE_CHART: DOBLE_CHART_CONFIGURATION[Clients.default]
    },
    LANGUAGE: Languages["es"]
}
console.log(REACT_APP_XEVAL_URI)
if (REACT_APP_XEVAL_URI) {

    // REACT_APP_XEVAL_API = REACT_APP_XEVAL_API[REACT_APP_XEVAL_API.length - 1] === '/'
    //     ? REACT_APP_XEVAL_API.substring(0, REACT_APP_XEVAL_API.length - 1)
    //     : REACT_APP_XEVAL_API;

    REACT_APP_XEVAL_URI = REACT_APP_XEVAL_URI[REACT_APP_XEVAL_URI.length - 1] === '/'
        ? REACT_APP_XEVAL_URI.substring(0, REACT_APP_XEVAL_URI.length - 1)
        : REACT_APP_XEVAL_URI;


    // REACT_APP_XEVAL_API_RESOURCE = REACT_APP_XEVAL_API_RESOURCE[0] !== '/'
    //     ? "/" + REACT_APP_XEVAL_API_RESOURCE
    //     : REACT_APP_XEVAL_API_RESOURCE;
    // REACT_APP_XEVAL_API_RESOURCE = REACT_APP_XEVAL_API_RESOURCE[REACT_APP_XEVAL_API_RESOURCE.length - 1] === '/'
    //     ? REACT_APP_XEVAL_API_RESOURCE.substring(0, REACT_APP_XEVAL_API_RESOURCE.length - 1)
    //     : REACT_APP_XEVAL_API_RESOURCE;

    config.XEVAL_URI = REACT_APP_XEVAL_URI
}

if (REACT_APP_CLIENT) {
    config.CLIENT = Clients[REACT_APP_CLIENT.toLowerCase()] ? Clients[REACT_APP_CLIENT.toLowerCase()] : Clients.default
    config.CLIENT_CONFIG.GRADE_SYSTEM = GRADE_SYSTEM_CLIENT[config.CLIENT];
    config.CLIENT_CONFIG.NAV_CONFIG = NAV_CONFIGURATION_CLIENT[config.CLIENT];
    config.CLIENT_CONFIG.ROUTES_CONFIG = ROUTES_CONFIGURATION_CLIENT[config.CLIENT];
    config.CLIENT_CONFIG.DOBLE_CHART = DOBLE_CHART_CONFIGURATION[config.CLIENT];
}

if (REACT_APP_LANGUAGE && Languages[REACT_APP_LANGUAGE]) {
    config.LANGUAGE = Languages[REACT_APP_LANGUAGE]
}


export default config